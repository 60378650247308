<template>
  <div class="container">
    <div class="container page-container">
      <div class="row gutter-0.5">
        <h2 class="col-12 m-0">
          Ready to order a report?
        </h2>
        <div class="font-17 col-12 mb-1">
          Please select a property and the type of condition report
        </div>
        <div class="col-12">
          <div
            v-if="!reportPayloadRef.selectedUnit"
            class="mb-3"
          >
            <router-link :to="{ name: 'app.properties.add' }">
              You can add new properties here
              <img
                class="forward-arrow font-13 ml-1"
                :src="Arrow"
              >
            </router-link>
          </div>
          <ElForm
            ref="selectPropertyForm"
            :model="reportPayloadRef"
            :rules="formValidations"
            @submit.native.prevent="submit"
          >
            <SdFloatLabel>
              <ElFormItem prop="unit_id">
                <UnitAutoComplete
                  :only-showing="false"
                  :unit-id="reportPayloadRef.unit_id"
                  :address="reportPayloadRef.address"
                  @set-unit="unitSelected"
                />
              </ElFormItem>
            </SdFloatLabel>
          </ElForm>
          <PropertyUnsupportedAreaAlert
            v-if="reportPayloadRef.selectedUnit && (!isPropertyInMarketRef || !marketInspectionAgentsAvailableRef)"
            :custom-message="customMessage"
          />
          <ConditionReportUnitAssignedAlert
            v-if="isConditionReportUnitAssigned.showAlert"
            :custom-message="isConditionReportUnitAssigned.message"
          />
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12 col-md-6 col-lg-4 showing-type-card first">
        <div class="content">
          <SdArt
            class="art"
            art="move-in-out"
          />
          <h3>MOVE-IN/OUT REPORT</h3>
          <div class="px-2">
            Great for documenting the status of the property before a new move in
          </div>
          <ElDivider />
          <ul
            v-if="$viewport.gt.md || ($viewport.lt.md && uiFlags.moveInOutReadMore)"
            class="px-4"
          >
            <li>Comprehensive PDF report with photos of (nearly) every inch of the property</li>
            <li>Covers details in every interior room: walls, doors, appliances, etc.</li>
            <li>Covers landscaping, exterior walls, gutter, roof that's visible from ground </li>
          </ul>
          <div
            v-if="reportPayloadRef.selectedUnit && isPropertyInMarketRef && marketInspectionAgentsAvailableRef"
            class="price font-11 text-gray-dark"
          >
            <strong class="text-gray-darker">
              ${{ uiFlags.oneBrPrice }}-{{ uiFlags.fourBrPlusPrice }}
            </strong>
            Per Report
          </div>
          <div
            class="row actions w-100 justify-content-center align-items-center mb-4"
            :class="{ 'justify-content-between': $viewport.lt.md }"
          >
            <div
              v-if="$viewport.lt.md && !uiFlags.moveInOutReadMore"
              class="col-auto"
            >
              <ElButton
                type="text"
                @click="uiFlags.moveInOutReadMore = true"
              >
                Read more
                <i class="sdicon-caret-down" />
              </ElButton>
            </div>
            <div
              class="col-auto center"
              :style="{ margin: $viewport.lt.md && uiFlags.moveInOutReadMore ? 'auto' : '' }"
            >
              <ElButton
                :class="$viewport.lt.md ? 'mt-0' : 'mt-4'"
                :disabled="isChooseButtonShouldBeDisabled(ConditionReport.REPORT_TYPE_IN_OUT)"
                type="primary"
                size="medium"
                @click="submit(ConditionReport.REPORT_TYPE_IN_OUT)"
              >
                Choose
              </ElButton>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 showing-type-card second">
        <div class="content">
          <SdArt
            class="art"
            art="periodic"
          />
          <h3>PERIODIC REPORT</h3>
          <div class="px-2">
            Great for full visibility into the status of the entire property during tenancy
          </div>
          <ElDivider />
          <ul
            v-if="$viewport.gt.md || ($viewport.lt.md && uiFlags.periodicReportReadMore)"
            class="px-4"
          >
            <li>Coordination with the tenant is handled by us</li>
            <li>Comprehensive PDF report with photos and comments of major damage</li>
            <li>Covers high-level condition of all aspects of the property</li>
          </ul>
          <div
            v-if="reportPayloadRef.selectedUnit && isPropertyInMarketRef && marketInspectionAgentsAvailableRef"
            class="price font-11 text-gray-dark"
          >
            <strong class="text-gray-darker">${{ uiFlags.periodicPrice }}</strong>
            Per report
          </div>
          <div
            class="row actions w-100 justify-content-center align-items-center mb-4"
            :class="{ 'justify-content-between': $viewport.lt.md }"
          >
            <div
              v-if="$viewport.lt.md && !uiFlags.periodicReportReadMore"
              class="col-auto"
            >
              <ElButton
                type="text"
                @click="uiFlags.periodicReportReadMore = true"
              >
                Read more
                <i class="sdicon-caret-down" />
              </ElButton>
            </div>
            <div
              class="col-auto center"
              :style="{ margin: $viewport.lt.md && uiFlags.periodicReportReadMore ? 'auto' : '' }"
            >
              <ElButton
                class="col-auto"
                :class="$viewport.lt.md ? 'mt-0' : 'mt-4'"
                :disabled="isChooseButtonShouldBeDisabled(ConditionReport.REPORT_TYPE_PERIODIC)"
                type="primary"
                size="medium"
                @click="submit(ConditionReport.REPORT_TYPE_PERIODIC)"
              >
                Choose
              </ElButton>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 showing-type-card third">
        <div class="content">
          <SdArt
            class="art"
            art="periodic"
          />
          <h3>VACANT PROPERTY CHECK</h3>
          <div class="px-2">
            Perfect for properties that are vacant and need condition checks throughout the vacancy
          </div>
          <ElDivider />
          <ul
            v-if="$viewport.gt.md || ($viewport.lt.md && uiFlags.vacancyCheckReportReadMore)"
            class="px-4"
          >
            <li>Covers high-level condition of major aspects of the property</li>
            <li>Comprehensive PDF report with photos and comments of major damage</li>
            <li>Ideal for properties that offer self-showing</li>
          </ul>
          <div
            v-if="reportPayloadRef.selectedUnit && isPropertyInMarketRef && marketInspectionAgentsAvailableRef"
            class="price font-11 text-gray-dark"
          >
            <strong class="text-gray-darker">${{ uiFlags.vacancyCheckPrice }}</strong>
            Per report
          </div>
          <div
            class="row actions w-100 justify-content-center align-items-center mb-4"
            :class="{ 'justify-content-between': $viewport.lt.md }"
          >
            <div
              v-if="$viewport.lt.md && !uiFlags.vacancyCheckReportReadMore"
              class="col-auto"
            >
              <ElButton
                type="text"
                @click="uiFlags.vacancyCheckReportReadMore = true"
              >
                Read more
                <i class="sdicon-caret-down" />
              </ElButton>
            </div>
            <div
              class="col-auto center"
              :style="{ margin: $viewport.lt.md && uiFlags.vacancyCheckReportReadMore ? 'auto' : '' }"
            >
              <ElButton
                class="col-auto"
                :class="$viewport.lt.md ? 'mt-0' : 'mt-4'"
                :disabled="isChooseButtonShouldBeDisabled(ConditionReport.REPORT_TYPE_VACANCY_CHECK)"
                type="primary"
                size="medium"
                @click="submit(ConditionReport.REPORT_TYPE_VACANCY_CHECK)"
              >
                Choose
              </ElButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container page-container">
      <div class="row justify-content-center col-12 pt-5">
        <div class="d-flex flex-wrap justify-content-center">
          <strong class="pr-1">Need to batch upload?</strong>
          Call our operations team, or send us an email:
          <div class="d-flex justify-content-center font-13 align-items-center mt-1 mb-3">
            <SdEmailIndicator
              with-icon
              class="font-16"
              :email="Showdigs.SUPPORT_EMAIL"
            />
            <div class="dot bg-gray" />
            <SdPhoneIndicator
              with-icon
              class="font-16"
              :phone-number="Showdigs.SUPPORT_PHONE_NUMBER_HREF"
              :formatted-phone-number="Showdigs.SUPPORT_PHONE_NUMBER"
            />
          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-center">
          <strong>Need help?</strong>
          <a
            class="px-1 text-center"
            href="https://help.showdigs.com/en/collections/3127975-services#condition-reports"
            target="_blank"
          >
            Learn more about condition reports in our help center
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import SdArt from '@/components/common/SdArt';
import UnitAutoComplete from '@/components/unit/UnitAutoComplete';
import PropertyUnsupportedAreaAlert from '@/components/property/PropertyUnsupportedAreaAlert';
import ConditionReportUnitAssignedAlert from '@/components/condition-report/ConditionReportUnitAssignedAlert';
import Arrow from '@/assets/forward-arrow-blue.svg';
import Showdigs from '@/constants/Showdigs';
import { ConditionReport } from '@/constants/ConditionReport';
import SdEmailIndicator from '@/components/common/SdEmailIndicator';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import { getPricesValueByKey } from '@/utils/PriceUtil';
import Price from '@/constants/Price';

export default {
  name: 'ConditionReportType',
  components: {
    SdArt,
    UnitAutoComplete,
    PropertyUnsupportedAreaAlert,
    ConditionReportUnitAssignedAlert,
    SdEmailIndicator,
    SdPhoneIndicator,
  },
  props: {
    property: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props, context) {
    const uiFlags = reactive({
      moveInOutReadMore: false,
      periodicReportReadMore: false,
      vacancyCheckReportReadMore: false,
      periodicPrice: null,
      oneBrPrice: null,
      fourBrPlusPrice: null,
      vacancyCheckPrice: null,
    });
    const address = context.root.$route.params.address ? String(context.root.$route.params.address) : null;
    const selectedUnitId = context.root.$route.params.unit_id ? Number(context.root.$route.params.unit_id) : null;
    const isPropertyInMarketRef = ref(false);
    const marketInspectionAgentsAvailableRef = ref(false);
    const reportPayloadRef = ref({
      selectedUnit: null,
      isPropertyInMarket: null,
      selectedPlan: null,
      unit_id: selectedUnitId,
      choosePressed: false,
      address,
    });
    const choosePressed = false;
    const customMessage = `We're gradually expanding our service areas, but currently
      do not support activities at the location of this property. We'll make sure
      to notify you as soon as that changes`;
    const isConditionReportUnitAssigned = {
      showAlert: false,
      message: '',
      disabledTypes: [],
    };

    return {
      submit,
      ConditionReport,
      uiFlags,
      unitSelected,
      Arrow,
      isPropertyInMarketRef,
      reportPayloadRef,
      formValidations,
      choosePressed,
      marketInspectionAgentsAvailableRef,
      customMessage,
      isConditionReportUnitAssigned,
      isChooseButtonShouldBeDisabled,
      Showdigs,
    };

    function isChooseButtonShouldBeDisabled(conditionReportType) {
      if (isConditionReportUnitAssigned.disabledTypes?.includes(conditionReportType)) {
        return true;
      }

      return !isPropertyInMarketRef.value
        || !marketInspectionAgentsAvailableRef.value
        || reportPayloadRef.choosePressed;
    }

    function emitPayloadChanged() {
      context.emit('update-payload', reportPayloadRef);
    }

    async function submit(selectedPlan) {
      reportPayloadRef.value.selectedPlan = selectedPlan;
      reportPayloadRef.value.choosePressed = true;
      try {
        await context.refs.selectPropertyForm.validate();
      } catch (e) { }
      emitPayloadChanged();
    }

    async function unitSelected(unit) {
      context.refs.selectPropertyForm.clearValidate('unit_id');
      let market = null;
      isConditionReportUnitAssigned.showAlert = false;
      isConditionReportUnitAssigned.disabledTypes.splice(0, isConditionReportUnitAssigned.disabledTypes.length);

      // occupied
      try {
        await context.root.$store.dispatch('ConditionReport/checkForAssignedUnit', {
          unit_id: unit.id,
        });
      } catch (error) {
        // found an inspection assigned to the unit
        isConditionReportUnitAssigned.showAlert = true;
        isConditionReportUnitAssigned.message = error.response.data.message;
        isConditionReportUnitAssigned.disabledTypes = error.response.data.disabledTypes;
      }
      if (unit && unit.geo_point) {
        market = await context.root.$store.dispatch('Market/get', unit.geo_point);
        isPropertyInMarketRef.value = market;
      } else {
        isPropertyInMarketRef.value = true;
      }

      marketInspectionAgentsAvailableRef.value = market && market.inspection_agents_available;

      const url = `properties/${unit.property_id}/units/${unit.id}/condition-report/add`;
      window.history.pushState({}, '', url);
      reportPayloadRef.value.unit_id = unit.id;
      reportPayloadRef.value.selectedUnit = unit;
      reportPayloadRef.value.isPropertyInMarket = isPropertyInMarketRef.value;
      reportPayloadRef.value.choosePressed = false;

      uiFlags.periodicPrice = getPricesValueByKey(Price.PERIODIC, unit.market_id);
      uiFlags.oneBrPrice = getPricesValueByKey(Price.MOVE_IN_OUT_SMALL, unit.market_id);
      uiFlags.fourBrPlusPrice = getPricesValueByKey(Price.MOVE_IN_OUT_LARGE, unit.market_id);
      uiFlags.vacancyCheckPrice = getPricesValueByKey(Price.VACANT_PROPERTY, unit.market_id);
      emitPayloadChanged();
    }
  },
};

const formValidations = {
  unit_id: [
    { required: true, trigger: 'blur', message: 'Property is required' },
  ],
};
</script>

<style lang="scss" scoped>
@include media-breakpoint-up(md) {
  .page-container {
    width: map-get($container-max-widths, 'md');
  }

  .showing-type-card {
    &.first {
      padding-right: 15px;
    }

    &.second {
      padding-left: 15px;
    }

    &.third {
      padding-right: 15px;
    }
  }
}

.forward-arrow {
  position: relative;
  top: 3px;
}

.showing-type-card {

  &:hover,
  &:active {
    .content {
      border-color: theme-color();
      background-color: rgba(theme-color(), 0.03);

      .sd-art {
        background-position: bottom left;
      }
    }
  }

  .content {
    height: 100%;
    padding-top: 1rem;
    border: 1px solid gray-color('light');
    text-align: center;

    .art {
      margin: 0 auto;
    }

    h3 {
      margin-top: 0.5rem;
      font-size: $--font-size-base;
      color: gray-color('darker');
    }

    ul {
      list-style: none;
      align-self: start;
      text-align: left;
      font-size: $--font-size-small;
      color: gray-color('darker');
      line-height: 1.25rem;
      padding-left: 0;
      margin-bottom: 0;
      min-height: 10rem;

      li {
        margin-bottom: 0.5rem;
        padding-left: 1rem;
        text-indent: -1.3rem;

        &::before {
          content: "\2022";
          color: theme-color();
          margin-right: 1rem;
          font-size: $--font-size-medium;
        }
      }
    }

    .price {
      strong {
        font-weight: 500;
        font-size: 42px;
        display: block;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .showing-type-card {
    .content {
      .price {
        order: 1;
      }

      ul {
        margin-top: 1rem;
        order: 2;
      }

      .actions {
        order: 3;
      }

      .report-example {
        order: 4;
      }
    }
  }
}
</style>
